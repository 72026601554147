import React, { useEffect } from "react"

import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  UncontrolledAlert,
} from "reactstrap"

import { connect } from "react-redux";
import { Link, Redirect, useNavigate, useLocation } from 'react-router-dom';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const Success = (props) => {
  document.title = "Opérations | Enregistrement réussi";
  const navigate = useNavigate();
  const location = useLocation();
  const { operation } = location.state || {};
  const breadcrumbItems = [
    // { title: "Lexa", link: "#" },
    // { title: "UI Elements", link: "#" },
    // { title: "Alerts", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Enregistrement Réussie', breadcrumbItems)
  })

  return (
    <React.Fragment>

      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <div className="">
                <Row>
                  <Col sm={12}>
                    <Alert color="success" className="alert-dismissible fade show mt-4 px-4 mb-0 text-center" role="alert">
                      <i className="mdi mdi-check-circle-outline d-block display-4 mt-2 mb-3 text-success"></i>
                      <h2 className="text-success">Félicitations!!</h2>
                      <p>
                        <h2 className="text-success">Numéro de colis: </h2>  <h1 style={{ fontWeight: "bold" }} className="text-warning">{operation.opCode} </h1>
                      </p>
                      <p style={{ fontSize: "18pt" }}>Le colis a été enregistré avec succès. Veuillez à bien vouloir procéder à son expédition dans un délai 2h de temps </p>
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">

                      </button>
                    </Alert>
                  </Col>

                </Row>
              </div>

            </CardBody>
          </Card>

          <Card>
            <Col sm={12}>
                <button type="button" style={{ width: "100%" }} onClick={()=>{navigate('/operations')}} className="btn btn-lg d-block btn-warning" id="liveAlertBtn">FAIRE UN NOUVEL ENREGISTREMENT</button>
            </Col>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Success);