import React, { useState } from 'react';
import { Table, Card, CardBody, Button } from "reactstrap";


// Import Images
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import moment from 'moment';

const LatestTransactions = ({latest}) => {

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Colis récents</h4>

          <div className="table-responsive">
            <Table className="align-middle table-centered table-vertical table-nowrap">
              <tbody>
                {latest.map((item, key) => (
                  <tr key={key}>
                    <td>
                      {item.opAgdest.agNom}
                    </td>
                    <td>
                      {/* <img
                        src={user2}
                        alt="user"
                        className="avatar-xs rounded-circle me-2"
                      />{" "} */}
                      {item.opCode}
                    </td>
                    <td>
                      <i
                        className={
                          "mdi mdi-checkbox-blank-circle text-danger" //+ transaction.color
                        }
                      ></i>{" "}
                      {item.opStat.etatLibelle}
                    </td>
                    <td>
                      {moment(item.opCreate).format("DD-MM-YYYY HH:mm")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LatestTransactions;
