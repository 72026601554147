import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import Switch from "react-switch"
import * as url from "./../../helpers/url_helper"
import { array } from "prop-types";

const Offsymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        fontWeight: 'bold',
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      OFF
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        fontWeight: 'bold',
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      ON
    </div>
  )
}
const animatedComponents = makeAnimated()
const User = (props) => {
  document.title = "Abidjan DAI APP | Utilisateurs";
  
  const [options, setOptions]= useState([])
  const [users, setUsers]= useState([])
  const [nom, setNom]= useState("")
  const [pnom, setPnom]= useState("")
  const [email, setEmail]= useState("")
  const [phone, setPhone]= useState("")
  const [errorMsg, setErrorMsg]= useState("")
  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
    { title : "Paramètres", link : "#" },
    { title : "Utilisateurs", link : "#" },
  ]
  const [selectedMulti3, setselectedMulti3] = useState([])
  const [switch1, setswitch1] = useState(true)

  useEffect(() => { props.setBreadcrumbItems('Utilisateurs', breadcrumbItems); getRoles(); getUsers() }, [])
  
  const changeUserStatus= (usId)=>{
    //setswitch1(!switch1);
    setswitch1(false);
  }
  const getRoles= ()=>{
    const role= [];
    fetch(url.BACKEND_URL+"/role", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        responseJson.data.forEach(elmt => {
        role.push({label: elmt.roleLibelle, value: elmt.roleId})
      });
      setOptions(role);
       } else {  }
    }) .catch((error) => {  });
  }

  const getUsers= ()=>{
    fetch(url.BACKEND_URL+"/user", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        setUsers(responseJson.data);
        console.log(responseJson.data);
       } else {  }
    }) .catch((error) => {  });
  }
  function handleMulti(selectedMulti3) { setselectedMulti3(selectedMulti3) }
  
  const saveUser= ()=>{
    const roles=[];
    selectedMulti3.forEach((elmt)=>{ roles.push({roleId: elmt.value, roleLibelle: elmt.label}); });
    
    fetch(url.BACKEND_URL+"/user", {
      method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({usName: nom+" "+pnom, usPhone: phone, usLogin: email, usPassword: "abc123", usRole: roles})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        setselectedMulti3([]); setNom(""); setPhone(""); setEmail(""); setPnom("");
        getUsers();
       } else { setErrorMsg(responseJson.message); }
    }) .catch((error) => {  });
    
  }
  return (
    <React.Fragment>

      <Row>
        <Col xl={5}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="formrow-email-input">Nom</label>
                            <input type="text" className="form-control" id="nom" value={nom} onChange={(event)=>setNom(event.target.value)} placeholder="Nom de l'utilisateur"/>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="formrow-password-input">Prénoms</label>
                            <input type="text" className="form-control" id="formrow-password-input" value={pnom} onChange={(event)=>setPnom(event.target.value)} placeholder="prénoms de l'utilisateur"/>
                          </div>
                        </Col>
                      </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Email</label>
                          <input type="email" className="form-control" id="formrow-email-input" value={email} onChange={(event)=>setEmail(event.target.value)} placeholder="Email de l'utilisateur"/>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-password-input">Téléphone</label>
                          <input type="text" className="form-control" id="formrow-password-input" value={phone} onChange={(event)=>setPhone(event.target.value)} placeholder="Téléphone"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                      <div className="mb-3 templating-select select2-container">
                        <label className="control-label">Role</label>
                        <Select
                          value={selectedMulti3}
                          isMulti={true}
                          onChange={handleMulti}
                          options={options}
                          classNamePrefix="select2-selection"
                          components={animatedComponents}
                        />
                      </div>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <button type="button" onClick={()=>saveUser()} className="btn btn-primary w-md">Enregistrer</button>
                    </div>
                    <Row>
                      <Col md={12}>
                        <span>{errorMsg}</span>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={7}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste utilisateurs</CardTitle>
              

              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom</th>
                      <th>Téléphone</th>
                      <th>Login</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((item, key)=>(
                      <tr>
                      <th scope="row">{item.usId}</th>
                      <td>{item.usName}</td>
                      <td>{item.usPhone}</td>
                      <td>{item.usLogin}</td>
                      <td>
                        <Switch
                          uncheckedIcon={ <Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          onChange={() => changeUserStatus(item.usId)}
                          checked={item.usStatus=="ENABLED"? true: false}
                        />
                      </td>
                    </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(User);
