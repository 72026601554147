import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import React, {useContext, useState, useEffect} from 'react';
import config from "common/configs/config";
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import axios from "axios";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
      const response = yield call(postJwtLogin, { login: user.email, password: user.password });
      localStorage.setItem("token", response.data);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      yield put(loginSuccess(response));

      history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear();

    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const fireBaseBackend = getFirebaseBackend();
  //     const response = yield call(fireBaseBackend.socialLoginUser, type);
  //     if (response) {
  //       history("/dashboard");
  //     } else {
  //       history("/login");
  //     }
  //     localStorage.setItem("authUser", JSON.stringify(response));
  //     yield put(loginSuccess(response));
  //   }
  //   const response = yield call(fireBaseBackend.socialLoginUser, type);
  //   if(response)
  //   history("/dashboard");
  // } catch (error) {
  //   yield put(apiError(error));
  // }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
