import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import * as url from "../../helpers/url_helper"
import moment from 'moment'; 
import PropTypes from "prop-types";
import withRouter from 'components/Common/withRouter';
import { color } from "echarts";

const animatedComponents = makeAnimated()
const Rang = (props) => {
  document.title = "Abidjan DAI APP | Utilisateurs";
  const navigate = useNavigate();

  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
    { title : "Paramètres", link : "#" }, { title : "Rang Dojo", link : "#" },]
  const [rang, setRang]= useState(null);
  const [rangs, setRangs]= useState([]);
  const [errorMsg, setErrorMsg]= useState("");
  
  useEffect(() => { props.setBreadcrumbItems('Paramétrage Rang Dojo', breadcrumbItems); getRangs() }, [])
  
  const saveRang= ()=>{
    fetch(url.BACKEND_URL+"/rang", {
      method: 'POST', headers: { 'Authorization': "Bearer "+localStorage.getItem("token"), Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({rdjName: rang})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { setRang(""); getRangs(); } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else { setErrorMsg(responseJson.message);  }
    }) .catch((error) => {  });
    //navigate('/logout');
  }
  
  const getRangs= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/rang", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      //alert(responseJson.status)
      if (responseJson.status==200) { setRangs(responseJson.data); } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }
  const testSup= ()=>{
    alert("Test Sup");
  }
  return (
    <React.Fragment>

      <Row>
        <Col xl={6}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Rang</label>
                          <input type="text" value={rang} onChange={(event)=>setRang(event.target.value)} className="form-control" id="role" placeholder="Nom du rang"/>
                        </div>
                      </Col>
                      <Col md={12}><strong style={{ "color": "red"  }}>{errorMsg}</strong></Col>
                    </Row>
                    <div className="mt-4">
                      <button type="button" className="btn btn-primary w-md" onClick={()=>saveRang()}>Enregistrer</button>
                    </div>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste des rangs</CardTitle>
              
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du rang</th>
                      <th>Suprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                  {rangs.map((item, key) => (
                    <tr>
                    <th scope="row">{item.rdjId}</th>
                    <td>{item.rdjName}</td>
                    <td><a onClick={()=>testSup()} className="fas fa-window-close" style={{ fontSize: 20 }}></a></td>
                  </tr>
                  ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Rang);
