import React,{useEffect, useState} from "react"

import { Row, Col, Card, CardBody, CardTitle, InputGroup, Label, FormGroup, Button, Modal, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { connect } from "react-redux";
import * as url from "../../helpers/url_helper"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import moment from "moment"
import { RingLoader, PulseLoader, BarLoader } from "react-spinners";
const Bordereaux = (props) => {
  document.title = "Opérations | Interface des opérations";

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Tables", link: "#" },
    { title: "Responsive Table", link: "#" },
  ]
  const [loadingBd, setLoadingBd] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [agences, setAgences] = useState([]);
  const [etats, setEtats] = useState([{label: "En attente", value: 1}, {label: "Expédié", value: 2}, {label: "Arrivé", value: 5}]);
  const [etat, setEtat] = useState('');
  const [dest, setDest] = useState('');
  const [selectedDest, setSelectedDest] = useState(null);
  const [selectedEtat, setSelectedEtat] = useState(null);
  const [bord, setBord] = useState();
  const [bords, setBords] = useState([]);
  const [operations, setOperations] = useState([]);
  const [operation, setOperation] = useState(null);
  const [totalBord, setTotalBord] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const optionGroup = [ { options: agences } ]
  const optionEtat = [ { options: etats } ]
  const handleStartDate= (date)=>{ setStartDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleEndDate= (date)=>{ setEndDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleDestination= (event)=>{ setSelectedDest(event); setDest(event.value); }
  const handleEtat= (event)=>{ setSelectedEtat(event); setEtat(event.value); }
  const [modal_declare_bord, setmodal_declare_bord] = useState(false)
  const [modal_depouille_bord, setmodal_depouille_bord] = useState(false)
  const [modal_view_bord, setmodal_view_bord] = useState(false)
  let userJson= JSON.parse(localStorage.getItem("user"));
  let [loading, setLoading] = useState(false);
  let [loadingBord, setLoadingBord] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});

  const loadAgences= ()=> {
    let userJson= JSON.parse(localStorage.getItem("user"));
    let agencesArray= [{label: "Toutes les agences", value: ''}];
    fetch(url.BACKEND_URL+"/agence?etp="+userJson.usAgence.agEtp.etpId+"&size=100", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        agencesArray=[
          ...agencesArray,
          ...responseJson.data.map(item=>({
          label: item.agNom,
          value: item.agId
        }))];
        setAgences(agencesArray);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const declareColis = (itemOp) => {
    setLoadingRows(prev => ({ ...prev, [itemOp.opCode]: true }));

    fetch(url.BACKEND_URL+"/operation/declare?opId="+itemOp.opId+"&usId="+userJson.usId, {
      method: 'GET', headers: { Accept: 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(prevOperations => 
          prevOperations.map(op => 
            op.opCode === itemOp.opCode
              ? { ...op, opStat: { ...op.opStat, etatId: 3, etatLibelle: 'ARRIVE A DESTINATION' } }
              : op
          )
        );
        setLoadingRows(prev => ({ ...prev, [itemOp.opCode]: false }));
        // loadOperations(bord)
    } else {  }
    }) .catch((error) => {  });
  };


  const loadBordereaux=(page)=> {
    setLoadingBd(true)
    fetch(url.BACKEND_URL+"/bordereau?start="+startDate+"&end="+endDate+"&page="+page+"&size=30&agdest="+userJson.usAgence.agId+"&agexp="+dest+"&statNot=1", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setBords(responseJson.data)
        setTotalBord(responseJson.totalElement)
        setTotalPage(responseJson.totalPage)
        setCurrPage(responseJson.current_page)
        setLoadingBd(false)
    } else { setLoadingBd(false) }
    }) .catch((error) => { setLoadingBd(false) });
  };
  
  useEffect(() => {
    props.setBreadcrumbItems('Vos envois enregistrés')
    loadAgences()
    loadBordereaux(0)
  }, [])
  function removeBodyCss() { document.body.classList.add("no_padding") }
  function tog_declare_bord() { setmodal_declare_bord(!modal_declare_bord); removeBodyCss() }
  function tog_depouille_bord() { setmodal_depouille_bord(!modal_depouille_bord); removeBodyCss() }
  function tog_view_bord() { setmodal_view_bord(!modal_view_bord); removeBodyCss() }
  
  const getOperation=(item)=> {
    return (
      <Row>
        <Col xl="12">
          <div className="table-rep-plugin">
            <div className="table-responsive mb-0" data-pattern="priority-columns">
            <Table id="tech-companies-1" className="table table-striped table-bordered">
              <Thead>
                <Tr>
                  <Th>
                    <strong>Provenance</strong>
                  </Th>
                  <Td>Code colis</Td>
                  <Td>Date création</Td>
                  <Td>Expéditeur</Td>
                  <Td>Bénéficiaire</Td>
                  <Td>Frais</Td>
                  <Td style={{ fontSize: 9 }}>Action</Td>
                </Tr>
              </Thead>
              <Tbody>
                  {operations.map((item, key)=>(
                  <Tr key={item.opCode} className={`border ${loadingRows[item.opCode] ? "bg-gray-100 opacity-70" : ""}`}>
                    <Th>
                      <strong>{item.opAgdest.agNom}</strong>
                    </Th>
                    <Td>{item.opCode}</Td>
                    <Td>{moment(item.opCreate).format("DD-MM-YY HH:mm:ss")}</Td>
                    <Td>{item.opExp} ({item.opPhonexp})</Td>
                    <Td>{item.opDest} ({item.opDestphone})</Td>
                    <Td>{item.opFrais} FCFA</Td>
                    <Td style={{ fontSize: 9 }}>{item.opStat.etatId==2 ? 
                    (
                    <Button 
                      onClick={() => declareColis(item)}
                      disabled={loadingRows[item.opCode]}
                      className={`px-2 py-1 text-xs ${loadingRows[item.opCode] ? 'bg-gray-400' : 'bg-blue-500'} text-white rounded`}>
                        {loadingRows[item.opCode] ? <PulseLoader color="#fff" size={8}/> : 'Déclarer'}
                    </Button>
                    ) 
                    : <strong>{item.opStat.etatLibelle}</strong>}</Td>
                  </Tr>
                  ))}
                </Tbody>
            </Table>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const declareBord= (item)=>{
    fetch(url.BACKEND_URL+"/bordereau/declare/"+item.bordId, {
      method: 'GET', headers: { Accept: 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        tog_declare_bord()
        loadOperations(item)
        loadBordereaux(0)
        tog_depouille_bord()
    } else {  }
    }) .catch((error) => {  });
  }

  const loadOperations=(item)=> { 
    setLoading(true);
    fetch(url.BACKEND_URL+"/operation?size=30&bord="+item.bordId, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(responseJson.data)
        setLoading(false);
    } else {  }
    }) .catch((error) => {  });
  };
  return (
    <React.Fragment>
      <Row>
        <Modal size="sm" isOpen={modal_declare_bord} toggle={() => { tog_declare_bord() }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">Déclaration Bordereau</h5>
          </div>
          <div className="modal-body">
            <h5 className="txt-primary">Confirmez-vous la réception de ce bordereau à votre agence?</h5>
            </div>
          <div className="modal-footer">
            <button type="button" onClick={() => {tog_declare_bord() }} className="btn btn-danger waves-effect" data-dismiss="modal">Fermer </button>
            <button type="button" onClick={()=>declareBord(bord)} className="btn btn-primary waves-effect waves-light">Confirmez</button>
          </div>
        </Modal>
        <Modal size="xl" isOpen={modal_depouille_bord} toggle={() => { tog_depouille_bord() }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">Consultation du bordereau</h5>
          </div>
            {loading ? (<Row style={{ margin: "0 auto", height: 100 }}><RingLoader size={60} color="#36d7b7"/></Row>): getOperation(operations)}
        </Modal>
        
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Début</Label>
            <InputGroup>
              
              <Flatpickr
                className="form-control d-block"
                placeholder="Date début"
                value={startDate}
                onChange={handleStartDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Fin</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Fin"
                value={endDate}
                onChange={handleEndDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2">
          <div className="mb-3">
            <Label>Provenance</Label>
            <Select
              value={selectedDest}
              onChange={handleDestination}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col lg="2">
          <div className="mb-3">
            <Label>Etat</Label>
            <Select
              value={selectedEtat}
              onChange={handleEtat}
              options={optionEtat}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col lg="2">
          <div className="mt-3">
            <Button type="button" style={{ width: "100%" }} onClick={()=>{loadBordereaux(0)}} color="primary" data-toggle="Button" size="lg" className="mt-2 waves-effect waves-light">Recharger</Button>
          </div>
        </Col>
      </Row>
     <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">{totalBord} élément(s) </CardTitle>
              <Card>
                {totalPage ==0 ? ( null): totalPage>=25 ? (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: 25}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadBordereaux(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(totalPage-1)}>......{totalPage-1}</PaginationLink></PaginationItem>
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>): (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: totalPage}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadBordereaux(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>)}
              </Card>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                   <div>
                      {loadingBd ? (<BarLoader width={"100%"} height={6}/>): null}
                    </div>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                    <Tr>
                        <Th>Destination</Th>
                        <Th data-priority="1">Code</Th>
                        <Th data-priority="3">Créateur</Th>
                        <Th data-priority="3">Date et heure</Th>
                        <Th data-priority="3">Etat</Th>
                        <Th data-priority="6">Détail</Th>
                        <Th data-priority="6">Déclarer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {bords.map((item, key)=>(
                      <Tr>
                        <Th>
                          <strong>{item.bordAgexp.agNom}</strong>
                        </Th>
                        <Td>{item.bordCode}</Td>
                        <Td>{item.bordUser.usNom}</Td>
                        <Td>{moment(item.bordCreate).format("DD-MM-YYYY HH:mm:ss")}</Td>
                        <Td style={{ fontSize: 9 }}><strong>{item.bordStat.etatLibelle}</strong></Td>
                        <Td><Button onClick={()=>{setBord(item); tog_depouille_bord(); loadOperations(item)}}><i className="fa fa-eye"></i></Button></Td>
                        <Td>
                          {
                          item.bordStat.etatId==2 ? (<Button onClick={()=>{setBord(item); tog_declare_bord()}} className="btn-primary">Déclarer</Button>):
                          item.bordStat.etatId==5 ? (<Button onClick={()=>{setBord(item); tog_depouille_bord(); loadOperations(item)}}  className="btn-success">Dépouiller</Button>):
                          ( null)}
                        </Td>
                      </Tr>
                      ))}
                      
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}
export default connect(null, { setBreadcrumbItems })(Bordereaux);