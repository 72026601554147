import React from 'react';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { Card, CardBody, Row , Col} from "reactstrap"

const DonutChart = ({columns}) => {
  const donut = {
    title: 'Colis par état',
    width: 30,
    label: { show: false },
  };

  const color = {
    pattern: ['#ba0004', '#FFB320', '#FF8320', '#05B175'],
  };

  const size = {
    height: 300,
  };

  return (
    <React.Fragment>
      <C3Chart data={columns} donut={donut} color={color} size={size} dir="ltr" />
    </React.Fragment>
  );
};

export default DonutChart;
