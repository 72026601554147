import React, { useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-dark.png";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from 'components/Common/withRouter';
import * as backendHelper from "../../helpers/fakebackend_helper";
// actions
import { loginUser, socialLogin } from "../../store/actions";

const Login = props => {
  document.title = "Connexion | Abidjan DAI Application";
  useEffect(()=> {
    const userToken= backendHelper.isUserAuthenticated();
    if (userToken==true) {
      props.router.navigate('/dashboard');
    }
  }, []);
  const dispatch = useDispatch();
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Entrez votre identifiant svp"),
      password: Yup.string().required("Entrez votre mot de passe svp"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });


  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(
    selectLoginState,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate));
  };
  
  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">

                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="60" className="auth-logo-dark" />
                      {/* <img src={logoLight} alt="" height="30" className="auth-logo-light" /> */}
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Bienvenue !</h4>
                    <p className="text-muted text-center">Veuillez vous connecter pour continuer.</p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return true;
                      }}
                    >
                      {error ? <Alert style={{ fontSize: '14pt' }} color="danger">{error}</Alert> : null}
                      <div className="mb-3">
                        <Label htmlFor="username">Identifiant</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Votre identifiant"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Mot de passe</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Mot de passe"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3 mt-4">
                        {/* <div className="col-6">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">Remember me
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <button className="btn btn-primary btn-lg w-md waves-effect waves-light" style={{ width: '100%' }} type="submit">Se connecter</button>
                        </div>
                      </Row>
                      <Row className="form-group mb-0">
                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i> Mot de passe oublié?</Link>
                        <div className="col-12 mt-4 d-flex justify-content-center">
                          <Link
                            to="#"
                            className="social-list-item bg-danger text-white border-danger"
                            onClick={e => {
                              e.preventDefault();
                              socialResponse("google");
                            }}
                          >
                            <i className="mdi mdi-google" />
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>Vous n'avez pas de compte ? <Link to="/register" className="text-primary"> Créer le maintenant </Link></p>
                © {new Date().getFullYear()} ABIDJAN DAI APP <span className="d-none d-sm-inline-block"> - <i className="mdi mdi-copyright text-primary"></i> by <a href='https://createsarl.com' target='_blank'>CREATED SARL.</a></span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
