import React, { Component } from 'react';
import { Table, Card, Badge, Button } from "reactstrap";

//Import Images
import user1 from "../../assets/images/users/user-1.jpg";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import moment from 'moment';

const LatestOrders =({latest}) => {
    

    return (
        <React.Fragment>
            <Card>
                <div className="card-body">
                    <h4 className="card-title mb-4">Bordereaux récents</h4>

                    <div className="table-responsive">
                        <Table className="align-middle table-centered table-vertical table-nowrap mb-1">

                            <tbody>
                                {
                                    latest.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.bordAgdest.agNom}</td>
                                            <td>
                                                {/* <img src={user1} alt="user" className="avatar-xs me-2 rounded-circle" /> */}
                                                {item.bordCode}
                                            </td>
                                            <td><Badge className={"rounded-pill bg-success"}>{item.bordStat.etatLibelle}</Badge></td>
                                            {/* <td>
                                                {item.bordUser.usNom}
                                            </td> */}
                                            <td>
                                                {moment(item.bordCreate).format("DD-MM-YYYY HH:mm")}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}

export default LatestOrders;