import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import * as url from "../../helpers/url_helper"
import moment from 'moment'; 
import PropTypes from "prop-types";
import withRouter from 'components/Common/withRouter';

const animatedComponents = makeAnimated()
const Secteur = (props) => {
  document.title = "Abidjan DAI APP | Secteurs";
  const navigate = useNavigate();

  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
  { title : "Paramètres", link : "#" }, { title : "Secteur", link : "#" },]
  const [options, setOptions]= useState([]);
  const [dojo, setDojo]= useState(null);
  const [secteurs, setSecteurs]= useState([]);
  const [secteur, setSecteur]= useState(null);
  const [desc, setDesc]= useState(null);
  const [errorMsg, setErrorMsg]= useState("");
  
  useEffect(() => { props.setBreadcrumbItems('Paramétrage Secteurs', breadcrumbItems); getDojos(); getSector() }, [])
  
  const saveSector= ()=>{
    fetch(url.BACKEND_URL+"/sector", {
      method: 'POST', headers: { 'Authorization': "Bearer "+localStorage.getItem("token"), Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({secDojo: {djId: dojo.value}, secName: secteur, secDesc: desc})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { setSecteur(""); setDesc(""); /*getPays();*/ } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else { setErrorMsg(responseJson.message);  }
    }) .catch((error) => {  });
    // const data= JSON.stringify({paysRegion: {rgId: region.value}, paysName: pays, paysCode: codePays});
    // console.log(data);
  }
  
  const getSector= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/sector", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      //alert(responseJson.status)
      if (responseJson.status==200) { setSecteurs(responseJson.data); } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }
  const getDojos= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/dojo", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      const dojos= [];
      if (responseJson.status==200) { 
        responseJson.data.forEach(elmt => {
          dojos.push({label: elmt.djName, value: elmt.djId})
        });
        setOptions(dojos);
      } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }

  function handleDojo(dojo) { setDojo(dojo) }
  return (
    <React.Fragment>

      <Row>
        <Col xl={6}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                    <Col md={12}>
                      <div className="mb-3 templating-select select2-container">
                        <label className="control-label">Dojo</label>
                        <Select
                          value={dojo}
                          onChange={handleDojo}
                          options={options}
                          components={animatedComponents}
                        />
                      </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Nom du secteur</label>
                          <input type="text" value={secteur} onChange={(event)=>setSecteur(event.target.value)} className="form-control" placeholder="Nom du secteur"/>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Description</label>
                          <input type="text" value={desc} onChange={(event)=>setDesc(event.target.value)} className="form-control" placeholder="Description"/>
                        </div>
                      </Col>
                      <Col md={12}><strong style={{ "color": "red"  }}>{errorMsg}</strong></Col>
                    </Row>
                    <div className="mt-4">
                      <button type="button" className="btn btn-primary w-md" onClick={()=>saveSector()}>Enregistrer</button>
                    </div>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste des secteurs</CardTitle>
              
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dojo</th>
                      <th>Secteur</th>
                      <th>Suprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                  {secteurs.map((item, key) => (
                    <tr>
                    <th scope="row">{item.secId}</th>
                    <td>{item.secDojo.djName}</td>
                    <td>{item.secName}</td>
                    <td>Del</td>
                  </tr>
                  ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Secteur);
