import React,{useEffect, useState} from "react"

import { Row, Col, Card, CardBody, CardTitle, InputGroup, Label, FormGroup, Button, Modal, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { connect } from "react-redux";
import * as url from "./../../helpers/url_helper"
import { setBreadcrumbItems } from "../../store/actions";
import moment from "moment"
import { RingLoader } from "react-spinners";
import { BarLoader } from "react-spinners";

const Bordereaux = (props) => {
  document.title = "Opérations | Interface des opérations";

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Tables", link: "#" },
    { title: "Responsive Table", link: "#" },
  ]
  const [loadingBord, setLoadingBord] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [agences, setAgences] = useState([]);
  const [dest, setDest] = useState('');
  const [selectedDest, setSelectedDest] = useState(null);
  const [operations, setOperations] = useState([]);
  const [lockMessage, setLockMessage] = useState(null);
  const [bords, setBords] = useState([]);
  const [totalBords, setTotalBords] = useState(0);
  const [car, setCar] = useState("");
  const [bordSend, setBordSend] = useState("");
  const [agTransit, setAgTransit] = useState(null);
  const [heure, setHeure] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const [selectedAgTransit, setSelectedAgTransit] = useState(null);
  const handleAgTransit= (event)=>{ setSelectedAgTransit(event); setAgTransit(event.value); }
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const optionGroup = [ { options: agences } ]
  const [modal_opera, setmodal_opera] = useState(false)
  const [modal_lock, setmodal_lock] = useState(false)
  const [modal_send, setmodal_send] = useState(false)
  let [loading, setLoading] = useState(false);
  let [lockLoading, setLockLoading] = useState(false);
  const [msgError, setMsgError]= useState("");
  const [msgSuccess, setMsgSuccess]= useState("");
  const handleStartDate= (date)=>{ setStartDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleEndDate= (date)=>{ setEndDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleSendDate= (date)=>{ setHeure(moment(date[0]).format("DD-MM-YYYY HH:mm")); }
  const handleDestination= (event)=>{ setSelectedDest(event); setDest(event.value); }
  const userJson= JSON.parse(localStorage.getItem("user"));

  const loadAgences= ()=> {
    let userJson= JSON.parse(localStorage.getItem("user"));
    let agencesArray= [{label: "Aucun transit", value: null}];
    fetch(url.BACKEND_URL+"/agence?etp="+userJson.usAgence.agEtp.etpId+"&size=100", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        agencesArray=[
          ...agencesArray,
          ...responseJson.data.map(item=>({
          label: item.agNom,
          value: item.agId
        }))];
        setAgences(agencesArray);
    } else {  }
    }) .catch((error) => {  });
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_get_opera() {
    setmodal_opera(!modal_opera)
    removeBodyCss()
  }
  function tog_lock_bord() {
    setmodal_lock(!modal_lock)
    removeBodyCss()
  }
  function tog_send_bord() {
    setmodal_send(!modal_send)
    removeBodyCss()
  }
  const loadBordereaux=(page)=> {
    setLoadingBord(true)
    fetch(url.BACKEND_URL+"/bordereau?start="+startDate+"&end="+endDate+"&page="+page+"&size=30&agexp="+userJson.usAgence.agId+"&agdest="+dest, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setBords(responseJson.data)
        setTotalBords(responseJson.totalElement)
        setTotalPage(responseJson.totalPage)
        setCurrPage(responseJson.current_page)
        setLoadingBord(false)
    } else {  setLoadingBord(false) }
    }) .catch((error) => { setLoadingBord(false) });
  };
  
  const loadOperation=(bordId)=> {
    setLoading(true);
    fetch(url.BACKEND_URL+"/operation?bord="+bordId, {
      method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status==200) { 
        setOperations(responseJson.data)
        setLoading(false);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const lockBord = (bordId) => {
    setLockLoading(true);
    
    fetch(url.BACKEND_URL + "/bordereau/lock/" + bordId, {
      method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}})
    .then((response) => {
      const statusCode = response.status;
      if (statusCode === 200) {
        return response.json().then((responseJson) => {
          setLockLoading(false);
          if (responseJson.data.bordLock === 1) {
            setLockMessage("Le bordereau a été verrouillé avec succès"); } 
          else { setLockMessage("Le bordereau a été déverrouillé avec succès"); }
          loadBordereaux();
        });
      } else {
        return response.json().then((responseJson) => {
          setLockLoading(false); setLockMessage(`Erreur (${statusCode}): ${responseJson.message}`);
        });
      }
    })
    .catch((error) => {
      setLockLoading(false); setLockMessage("Erreur lors de la requête: " + error.message);
    });
  };
  
  const verifData= ()=>{
    if (car.trim()==="") { setMsgError("Numéro de car non défini"); return false;}
    else if (heure.trim()==="") { setMsgError("Date et heure d'expédition non définie"); return false;}
    else {return true; }
  }

  const sendBordereau=()=>{
    if (verifData()) {
      fetch(url.BACKEND_URL+"/bordereau/send", {
        method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(
          {
            bordId: bordSend, 
            user: {usId: userJson.usId}, 
            heure: heure, 
            car: car,
            transit: {agid: agTransit}
          }) })
      .then((response) => response.json())
      .then((responseJson) => { 
        if (responseJson.status==200) { 
          setBords(prevBords => 
            prevBords.map(bord => 
              bord.bordId === bordSend
                ? { ...bord, bordStat: { ...bord.bordStat, etatId: 2, etatLibelle: 'Expédié' }
                , bordLock: 1 }
                : bord
            )
          );
          setMsgSuccess(responseJson.message)
          setCar(""); setHeure("");
          setTimeout(() => { tog_send_bord(); }, 500); 
      } else {  }
      }) .catch((error) => {  });
    }

    // const data=JSON.stringify(
    //         {
    //           bordId: bordSend, 
    //           user: {usId: userJson.usId}, 
    //           heure: heure, 
    //           car: car,
    //           transit: {agid: agTransit}
    //         });
    // alert(data)
  }

  useEffect(() => {
    props.setBreadcrumbItems('Bordereaux', breadcrumbItems)
    loadAgences()
    loadBordereaux(0)
  }, [])

  const getOperation=(item)=> {
    return (
        <Row>
          <Col xl="12">
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0" data-pattern="priority-columns">
              <Table id="tech-companies-1" className="table table-striped table-bordered">
              <Tbody>
                      {operations.map((item, key)=>(
                      <Tr>
                        <Th>
                          <strong>{item.opAgdest.agNom}</strong>
                        </Th>
                        <Td>{item.opCode}</Td>
                        <Td>{moment(item.opCreate).format("DD-MM-YY HH:mm:ss")}</Td>
                        <Td>{item.opExp} ({item.opPhonexp})</Td>
                        <Td>{item.opDest} ({item.opDestphone})</Td>
                        <Td>{item.opFrais} FCFA</Td>
                        <Td style={{ fontSize: 9 }}><strong>{item.opStat.etatLibelle}</strong></Td>
                      </Tr>
                      ))}
                    </Tbody>
              </Table>
              </div>
            </div>
          </Col>
        </Row>);
      };

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal_opera} toggle={() => { tog_get_opera() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Consultation du bordereau</h5>
        </div>
          {loading ? (<Row style={{ margin: "0 auto", height: 100 }}><RingLoader size={60} color="#36d7b7"/></Row>): getOperation(operations)}
      </Modal>
      <Modal size="sm" isOpen={modal_lock} toggle={() => { tog_lock_bord() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Vérrouillage bordereau</h5>
        </div>
          {lockLoading ? (<Row style={{ margin: "0 auto", height: 100 }}><RingLoader size={60} color="#36d7b7"/></Row>): (<Row><strong style={{ color: "#36d7b7" }}>{decodeURIComponent(lockMessage)}</strong></Row>)}
      </Modal>
      <Modal
        size="xl"
        isOpen={modal_send}
        toggle={() => { tog_send_bord() }}
        >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Formulaire d'envoi de bordereau</h5>
          <button
            onClick={() => {
              setmodal_send(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true" style={{ color: "black" }}>x</span> */}
          </button>
          <Col xl="3">
            <Button className="btn btn-lg btn-primary" tag="button" onClick={()=>{sendBordereau()}}>
              EXPEDIER LE BORDEREAU
            </Button>
          </Col>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl="4">
              <FormGroup className="mb-4">
                <div className="mb-3">
                <label htmlFor="example-datetime-local-input" className="col-md-12 col-form-label">Date et heure d'envoie</label>
                <div className="col-md-12">
                  <input
                    className="form-control"
                    type="datetime-local"
                    defaultValue={moment().format("YYYY-MM-DD HH:mm")}
                    id="example-datetime-local-input"
                    value={heure} 
                    onChange={(event)=>setHeure(event.target.value)}
                  />
                </div>
                </div>
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">NUméro du car</label>
                  <input type="text" value={car} onChange={(event)=>setCar(event.target.value)}  className="form-control" id="exp" placeholder="Numéro du car"/>
                </div>
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup className="mb-4">
              <div className="mb-3">
                  <Label>Zone de transit</Label>
                  <Select
                    value={selectedAgTransit}
                    onChange={handleAgTransit}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <div className="clearfix"></div>
          <Row>
            <Col xl="6">
              <strong style={{ fontSize: 16, color:"rgb(236, 83, 108)"}}>{msgError}</strong>
              <strong style={{ fontSize: 16, color: "#02a499" }}>{msgSuccess}</strong>
            </Col>
          </Row>
        </div>
      </Modal>
      <Row>
        <Col xl="3">
          <FormGroup className="mb-4">
            <Label>Début</Label>
            <InputGroup>
              
              <Flatpickr
                className="form-control d-block"
                placeholder="Date début"
                value={startDate}
                onChange={handleStartDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xl="3">
          <FormGroup className="mb-4">
            <Label>Fin</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Fin"
                value={endDate}
                onChange={handleEndDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        
        <Col lg="3">
          <div className="mb-3">
            <Label>Destination</Label>
            <Select
              value={selectedDest}
              onChange={handleDestination}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col lg="3">
          <div className="mt-3">
            <Button type="button" style={{ width: "100%" }} onClick={()=>{loadBordereaux(0)}} color="primary" data-toggle="Button" size="lg" className="mt-2 waves-effect waves-light">Rechargé</Button>
          </div>
        </Col>
      </Row>
     <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">{totalBords} élément(s) </CardTitle>
              <Card>
                {totalPage ==0 ? ( null): totalPage>=25 ? (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: 25}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadBordereaux(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(totalPage-1)}>......{totalPage-1}</PaginationLink></PaginationItem>
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>): (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: totalPage}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadBordereaux(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadBordereaux(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>)}
              </Card>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <div>
                    {loadingBord ? (<BarLoader width={"100%"} height={6}/>): null}
                  </div>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Destination</Th>
                        <Th data-priority="1">Code</Th>
                        <Th data-priority="3">Créateur</Th>
                        <Th data-priority="3">Date et heure</Th>
                        <Th data-priority="3">Etat</Th>
                        <Th data-priority="6">Détail</Th>
                        <Th data-priority="6">Verrou</Th>
                        <Th data-priority="6">Envoyer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {bords.map((item, key)=>(
                      <Tr>
                        <Th>
                          <strong>{item.bordAgdest.agNom}</strong>
                        </Th>
                        <Td>{item.bordCode}</Td>
                        <Td>{item.bordUser.usNom}</Td>
                        <Td>{moment(item.bordCreate).format("DD-MM-YYYY HH:mm:ss")}</Td>
                        <Td style={{ fontSize: 9 }}><strong>{item.bordStat.etatLibelle}</strong></Td>
                        <Td><Button onClick={()=>{loadOperation(item.bordId); setTimeout(() => { tog_get_opera() }, 200);}}><i className="fa fa-eye"></i></Button></Td>
                        {item.bordLock==0 ? (<Td><Button onClick={()=>{lockBord(item.bordId); setTimeout(() => { tog_lock_bord() }, 200);}} className="btn-success"><i className="fa fa-lock-open"></i></Button></Td>): (<Td><Button onClick={()=>{lockBord(item.bordId); setTimeout(() => { tog_lock_bord() }, 200);}}  className="btn-danger"><i className="fa fa-lock"></i></Button></Td>)}
                        <Td>{item.bordStat.etatId==1 ? (<Button onClick={()=>{setBordSend(item.bordId); tog_send_bord()}} className="btn-primary">Envoyer</Button>): (<strong>{item.bordStat.etatLibelle}</strong>)}</Td>
                      </Tr>
                      ))}
                      
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}
export default connect(null, { setBreadcrumbItems })(Bordereaux);