import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

const MonthlyEarnings2 = props =>{
    const options= {
        colors: ['#28bbe3', '#F0F1F4'],
        chart: {
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                columnWidth: '70%',
            },
        },
        grid: {
            borderColor: '#f8f8fa',
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },

        xaxis: {
            categories: props.infos.axe,
            labels: {
                formatter: function (val) {
                    return val
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },

        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    };
    const series= [{
        name: 'Series A',
        data: props.infos.value
    }];
        
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Courbe des 6 derniers jours</h4>

                    <div id="morris-bar-stacked" className="morris-charts morris-charts-height" dir="ltr">
                        <ReactApexChart options={options} series={series} type="bar" height="300" />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default MonthlyEarnings2;