import React,{useEffect, useState} from "react"

import { Row, Col, Card, CardBody, CardTitle, InputGroup, Label, FormGroup, Button, Modal, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link, Redirect, useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { connect } from "react-redux";
import * as url from "./../../helpers/url_helper"
import { setBreadcrumbItems } from "../../store/actions";
import moment from "moment"
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Switch from "react-switch"
import { Chrono } from "react-chrono";
import { BarLoader } from "react-spinners";

const Operations = (props) => {
  document.title = "Opérations | Interface des opérations";

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Tables", link: "#" },
    { title: "Responsive Table", link: "#" },
  ]
  const [loadingOps, setLoadingOps] = useState(false);
  const [opType, setOpType] = useState("DEFAULT");
  const [agences, setAgences] = useState([]);
  const [dest, setDest] = useState('');
  const [agDest, setAgDest] = useState('');
  const [selectedDest, setSelectedDest] = useState(null);
  const [selectedAgDest, setSelectedAgDest] = useState(null);
  const [selectedEmb, setSelectedEmb] = useState(null);
  const [nature, setNature] = useState(null);
  const [emballage, setEmballage] = useState(null);
  const [operations, setOperations] = useState([]);
  const [emballages, setEmballages] = useState([]);
  const [allEcoms, setAllEcoms] = useState([]);
  const [ecoms, setEcoms] = useState([]);
  const [ecom, setEcom] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [status, setStatus] = useState("");
  const [selectEcom, setSelectEcom] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [natures, setNatures] = useState([]);
  const [totalOps, setTotalOps] = useState(0);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const optionGroup = [ { options: agences } ]
  const [searchNat, setSearchNat] = useState("");
  const optionEmballages = [ { options: emballages } ]
  const optionEcom = [ { options: ecoms } ]
  const optionStatus = [ { options: [{label: "En attente", value: 1}, {label: "Expédié", value: 2}, {label: "Arrivé à destination", value: 3}, {label: "Livré", value: 4}] } ]
  const handleStartDate= (date)=>{ setStartDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleEndDate= (date)=>{ setEndDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleDestination= (event)=>{ setSelectedDest(event); setDest(event.value); }
  const handleEmballage= (event)=>{ setSelectedEmb(event); setEmballage(event.value); }
  const handleAgDestination= (event)=>{ setSelectedAgDest(event); setAgDest(event.value); }
  const handleEcom= (event)=>{ setSelectEcom(event); setEcom(event.value); 
    const result = allEcoms.find(item => item.ecomId === event.value);
    setExp(result.ecomNom); setPhoneExp(result.ecomPhone);
  }
  const handleStatus= (event)=>{ setSelectedStatus(event); setStatus(event.value); }
  const [detail, setDetail] = useState(null);
  const [phoneExp, setPhoneExp] = useState("")
  const [frais, setFrais] = useState("")
  const [valeur, setValeur] = useState("")
  const [exp, setExp] = useState("")
  const [benef, setBenef] = useState("")
  const [phoneDest, setPhoneDest] = useState("")
  const [modal_large, setmodal_large] = useState(false)
  const [modal_opera, setmodal_opera] = useState(false)
  const [smsActive, setSmsActive] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null); 
  const userJson= JSON.parse(localStorage.getItem("user"));
  const [msgError, setMsgError]= useState("")
  const [msgSuccess, setMsgSuccess]= useState("")
  const [readOnly, setReadOnly]= useState(false)
  const [displayEcom, setDisplayEcom]= useState("none")
  const [displayExp, setDisplayExp]= useState("block")
  const [numColis, setNumColis]= useState("")
  const navigate = useNavigate();
  const Offsymbol = (props) => {
    return (
      <div
        style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 12, color: "#fff", paddingRight: 2 }}>
        {" "} NON
      </div>
    )
  }
  
  const handleClick = (key) => {
    setSelectedRow(key); 
  };

  const OnSymbol = () => {
    return (
      <div
        style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 12, color: "#fff", paddingRight: 2 }}>
        {" "} OUI
      </div>
    )
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_form_opera() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }
  
  function tog_get_opera() {
    setmodal_opera(!modal_opera)
    removeBodyCss()
  }

  const loadAgences= ()=> {
    let userJson= JSON.parse(localStorage.getItem("user"));
    let agencesArray= [{label: "Toutes les agences", value: ''}];
    fetch(url.BACKEND_URL+"/agence?etp="+userJson.usAgence.agEtp.etpId+"&size=100", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        agencesArray=[
          ...agencesArray,
          ...responseJson.data.map(item=>({
          label: item.agNom,
          value: item.agId
        }))];
        setAgences(agencesArray);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadOperations=(page)=> {
    setLoadingOps(true)
    fetch(url.BACKEND_URL+"/operation?start="+startDate+"&end="+endDate+"&page="+page+"&size=30&user="+userJson.usId+"&agdest="+dest+"&stat="+status+"&code="+numColis, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(responseJson.data)
        setTotalOps(responseJson.totalElement)
        setTotalPage(responseJson.totalPage, 30)
        setCurrPage(responseJson.current_page, 30)
        setLoadingOps(false)
    } else { setLoadingOps(false)  }
    }) .catch((error) => {  setLoadingOps(false) });
  };
  
  const loadNature=(search)=> {
    fetch(url.BACKEND_URL+"/nature?search="+search, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setNatures(responseJson.data);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadEmballage=()=> {
    let embalArray= [{label: "Toutes les agences", value: ''}];
    fetch(url.BACKEND_URL+"/emballage", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        embalArray=[
          ...embalArray,
          ...responseJson.data.map(item=>({
            label: item.embalLibelle,
            value: item.embalId
          }))
        ]
        setEmballages(embalArray)
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadEcommerce=()=> {
    let ecomArray= [];
    fetch(url.BACKEND_URL+"/ecommerce", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        setAllEcoms(responseJson.data);
        ecomArray=[
          ...ecomArray,
          ...responseJson.data.map(item=>({
            label: item.ecomNom+" ["+item.ecomPhone+"]",
            value: item.ecomId
          }))
        ]
        setEcoms(ecomArray)
    } else {  }
    }) .catch((error) => {  });
  };

  const getOperation=(item)=> {
    let timeLine=[];
    timeLine= [
      ...timeLine,
      ...item.actionColis.map(ite=>(
        {
          title: ite.actNom,
          cardTitle: ite.actNom,
          cardSubtitle: ite.actUser.usNom,
          cardDetailedText: moment(ite.actCreate).format("DD-MM-YYYY HH:mm:SS")
        }
      ))
    ]
    console.log(timeLine);
    return (
        <Row>
          <Col xl="7">
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0" data-pattern="priority-columns">
              <Table id="tech-companies-1" className="table table-striped table-bordered">
                <Tbody>
                  <Tr><Td>Agence départ</Td><Td><strong>{item.opAgexp.agNom}</strong></Td></Tr>
                  <Tr><Td>Agence Bénéficiaire</Td><Td><strong>{item.opAgdest.agNom}</strong></Td></Tr>
                  <Tr><Td>Expéditeur</Td><Td><strong>{item.opExp} ({item.opPhonexp})</strong></Td></Tr>
                  <Tr><Td>Bénéficiaire</Td><Td><strong>{item.opDest} ({item.opDestphone})</strong></Td></Tr>
                  <Tr><Td>Code du colis</Td><Td><strong>{item.opCode}</strong></Td></Tr>
                  <Tr><Td>Emballage</Td><Td><strong>{item.detail[0].opdEmbal.embalLibelle}</strong></Td></Tr>
                  <Tr><Td>Nature</Td><Td><strong>{item.detail[0].opdNature.natLibelle}</strong></Td></Tr>
                  <Tr><Td>Valeur</Td><Td><strong>{item.opValeur} FCFA</strong></Td></Tr>
                  <Tr><Td>Frais</Td><Td><strong>{item.opFrais} FCFA</strong></Td></Tr>
                </Tbody>
              </Table>
              </div>
            </div>
          </Col>
          <Col xl="5" style={{ height: "500px", overflow: "auto" }}>
            <Chrono disableToolbar={true} mode="VERTICAL_ALTERNATING" cardHeight={80} fontSizes={{ title: "9pt", cardTitle: "9pt", cardSubtitle: "7pt", cardDetailedText: "5pt"}} cardWidth={180} items={timeLine} />
          </Col>
        </Row>);};
  
  useEffect(() => {
    props.setBreadcrumbItems('Vos envois enregistrés')
    loadAgences()
    loadOperations(0)
    loadEmballage()
    loadNature(searchNat)
    loadEcommerce()
  }, [])

  const verifData= ()=>{
    if (exp.trim()==="") { setMsgError("Expéditeur non défini"); return false;}
    else if (phoneExp.trim()==="" || phoneExp.length<8) { setMsgError("Contact expédteur incorrect"); return false;}
    else if (benef.trim()==="") { setMsgError("Bénéficiaire non défini"); return false;}
    else if (phoneDest.trim()==="" || phoneExp.length<8) { setMsgError("Contact bénéficiaire incorrect"); return false;}
    else if (agDest==="" || agDest==='undefined' || agDest==null) { setMsgError("Destination non sélectionnée"); return false;}
    else if (emballage==="" || emballage==='undefined' || emballage==null) { setMsgError("Emballage non sélectionné"); return false;}
    else if (nature==="" || nature==='undefined' || nature==null) { setMsgError("Nature non sélectionnée"); return false;}
    else if (valeur==="" || valeur==='undefined' || valeur==null) { setMsgError("Valeur non définie"); return false;}
    else if (frais==="" || frais==='undefined' || frais==null) { setMsgError("Frais non défini"); return false;}
    else {return true; }
  }
  
  const saveOperation=()=>{
    if (verifData()) {
      fetch(url.BACKEND_URL+"/operation", {
        method: 'POST', headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(
          {
            opType: opType, 
            opUser: {usId: userJson.usId, usNom: userJson.usNom}, 
            opValeur: valeur, 
            opFrais: frais, 
            opStat:{etatId: 1}, 
            opExp: exp, 
            opPhonexp: phoneExp, 
            opDest: benef,
            opDestphone: phoneDest,
            opAgexp: {agId: userJson.usAgence.agId, agCode: userJson.usAgence.agCode},
            opAgdest: {agId: agDest},
            opSms: +smsActive,
            opPlatform: "WEB1.0",
            detail: [{
              opdNature: {natId: nature},
              opdEmbal: {embalId: emballage}
            }]
          }) })
      .then((response) => response.json())
      .then((responseJson) => { console.log(responseJson.data);
        if (responseJson.status==200) { 
          loadOperations(0);
          setMsgSuccess(responseJson.message)
          navigate('/success', {state: {operation: responseJson.data}});
      } else {  }
      }) .catch((error) => {  });
    }
    //tog_form_opera();
    //navigate('/success', {state: {operation: {opId: 1, opCode: "0203CE01", opUser: {usId: 12}}}});
  }
  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal_opera} toggle={() => { tog_get_opera() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Consultation détails colis</h5>
        </div>
          {detail && getOperation(detail)}
      </Modal>
      <Modal
        size="xl"
        isOpen={modal_large}
        toggle={() => { tog_form_opera() }}
        >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Formulaire d'enregistrement de colis</h5>
          <button
            onClick={() => {
              setmodal_large(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" style={{ color: "black" }}>x</span>
          </button>
          <Col xl="3">
            <Button className="btn btn-lg btn-primary" tag="button" onClick={()=>{saveOperation()}}>
              ENREGISTRER L'OPERATION
            </Button>
          </Col>
        </div>
        <div className="modal-body">
          
          <Row>
            <Col xl="6" style={{ display: displayEcom }}>
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <Label>E-Commerçant</Label>
                  <Select
                    value={selectEcom}
                    onChange={handleEcom}
                    options={optionEcom}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" style={{ display: displayExp }}>
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Expéditeur</label>
                  <input type="text" value={exp} readOnly={readOnly} onChange={(event)=>setExp(event.target.value)} className="form-control" id="exp" placeholder="Nom de l'expéditeur"/>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3" style={{ display: displayExp }}>
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Contact expéditeur</label>
                  <PhoneInput
                    defaultCountry="ci"
                    value={phoneExp}
                    onChange={setPhoneExp}
                    inputStyle={{ width: "100%" }} 
                    inputProps={{
                      readOnly: readOnly,
                    }}
                    />
                    
                </div>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Bénéficiaire</label>
                  <input type="text" value={benef} onChange={(event)=>setBenef(event.target.value)} className="form-control" id="exp" placeholder="Nom de l'expéditeur"/>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Contact bénéficiaire</label>
                  <PhoneInput
                    defaultCountry="ci"
                    value={phoneDest}
                    onChange={setPhoneDest} 
                    inputStyle={{ width: "100%" }} 
                    />

                </div>
              </FormGroup>
            </Col>
          </Row>
          <div className="clearfix"></div>
          <Row>
            <Col xl="4">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <Label>Destination</Label>
                  <Select
                    value={selectedAgDest}
                    onChange={handleAgDestination}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </FormGroup>
            </Col>
            
            <Col xl="3">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <Label>Emballage</Label>
                  <Select
                    value={selectedEmb}
                    onChange={handleEmballage}
                    options={optionEmballages}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col xl="5">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Chercher la nature</label>
                  <input type="text"  value={searchNat} className="form-control" onChange={(event)=>setSearchNat(event.target.value)} onKeyUp={()=>{setTimeout(() => {loadNature(searchNat) }, 50);}} id="exp" placeholder="Recherchez la nature"/>
                </div>
                <div className="mb-3" style={{ display: "none" }}>
                  <input type="text" readOnly  value={nature} className="form-control" id="exp"/>
                </div>
              </FormGroup>
            </Col>
            
            <Col xl="3">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Valeur</label>
                  <input type="number" className="form-control" value={valeur} onChange={(event)=>setValeur(event.target.value)} id="exp" placeholder="Valeur du colis"/>
                </div>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">Frais</label>
                  <input type="number" className="form-control" value={frais} onChange={(event)=>setFrais(event.target.value)} id="exp" placeholder="Frais d'envoi"/>
                </div>
              </FormGroup>
            </Col>
            <Col xl="1">
              <FormGroup className="mb-4">
                <div className="mb-3">
                  <label className="form-label" htmlFor="formrow-firstname-input">SMS</label>
                  <div>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor="#02a499"
                      onChange={() => {
                        setSmsActive(!smsActive)
                      }}
                      checked={smsActive}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col xl="5" style={{ height: "180px", overflow: "auto", border: "1px solid gray", borderRadius: 10 }}>
              <Table>
                <Tbody>
                  {natures.map((item, key)=>(
                  <Tr key={item.natId} onClick={() => {handleClick(item.natId); setNature(item.natId); setSearchNat(item.natLibelle)}} style={{ backgroundColor: selectedRow === item.natId ? 'lightblue' : 'transparent'  }}>
                    <Th style={{ fontSize: 7, padding: 2}}>
                     <Button style={{ backgroundColor: "transparent", fontSize: 10, borderColor: "transparent", color: "black", padding: 0 }}><strong>{item.natLibelle}</strong></Button> 
                    </Th>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <strong style={{ fontSize: 16, color:"rgb(236, 83, 108)"}}>{msgError}</strong>
              <strong style={{ fontSize: 16, color: "#02a499" }}>{msgSuccess}</strong>
            </Col>
          </Row>
        </div>
      </Modal>
      <Row className="mb-4">
        <Col xl="4">
          <Button className="btn btn-lg btn-primary" tag="button" data-toggle="modal" data-target=".bs-example-modal-lg" onClick={() => {setReadOnly(false); setOpType("DEFAULT"); setDisplayExp("block"); setDisplayEcom("none"); tog_form_opera()}}>
            ENREGISTRER UN ENVOI SIMPLE
          </Button>
        </Col>
        <Col xl="4">
          <Button className="btn btn-lg btn-warning" onClick={()=>{setReadOnly(true); setOpType("ECOM"); setDisplayExp("none"); setDisplayEcom("block"); tog_form_opera()}} tag="button" >
            ENREGISTRER UN ENVOI E-COMMERCE
          </Button>
        </Col>
      </Row>
        
      <Row>
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Début</Label>
            <InputGroup>
              
              <Flatpickr
                className="form-control d-block"
                placeholder="Date début"
                value={startDate}
                onChange={handleStartDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Fin</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Fin"
                value={endDate}
                onChange={handleEndDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        
        <Col lg="2">
          <div className="mb-3">
            <Label>Destination</Label>
            <Select
              value={selectedDest}
              onChange={handleDestination}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col lg="2">
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              value={selectedStatus}
              onChange={handleStatus}
              options={optionStatus}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col xl="2">
          <FormGroup className="mb-3">
            <div className="mb-0">
              <label className="form-label" htmlFor="formrow-firstname-input">Numéro colis</label>
              <input type="text" className="form-control" value={numColis} onChange={(event)=>setNumColis(event.target.value)} id="exp" placeholder="Numéro du colis"/>
            </div>
          </FormGroup>
        </Col>
        <Col lg="2">
          <div className="mt-3">
            <Button type="button" style={{ width: "100%" }} onClick={()=>{loadOperations(0)}} color="primary" data-toggle="Button" size="lg" className="mt-2 waves-effect waves-light">Recharger</Button>
          </div>
        </Col>
      </Row>
     <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">{totalOps} élément(s) </CardTitle>
              <Card>
                {totalPage ==0 ? ( null): totalPage>=25 ? (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: 25}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadOperations(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(totalPage-1)}>......{totalPage-1}</PaginationLink></PaginationItem>
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>): (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: totalPage}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadOperations(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>)}
              </Card>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <div>
                    {loadingOps ? (<BarLoader width={"100%"} height={6}/>): null}
                  </div>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Destination</Th>
                        <Th data-priority="1">Code</Th>
                        <Th data-priority="3">Date heure</Th>
                        <Th data-priority="3">Expéditeur</Th>
                        <Th data-priority="1">Bénéficiaire</Th>
                        <Th data-priority="3">Frais</Th>
                        <Th data-priority="3">Etat</Th>
                        <Th data-priority="6">Détail</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {operations.map((item, key)=>(
                      <Tr>
                        <Th>
                          <strong>{item.opAgdest.agNom}</strong>
                        </Th>
                        <Td>{item.opCode}</Td>
                        <Td>{moment(item.opCreate).format("DD-MM-YY HH:mm:ss")}</Td>
                        <Td>{item.opExp} ({item.opPhonexp})</Td>
                        <Td>{item.opDest} ({item.opDestphone})</Td>
                        <Td>{item.opFrais} FCFA</Td>
                        <Td style={{ fontSize: 9 }}><strong>{item.opStat.etatLibelle}</strong></Td>
                        <Td><Button onClick={()=>{setDetail(item); setTimeout(() => { tog_get_opera() }, 100);}} ><i className="fa fa-eye"></i></Button></Td>
                      </Tr>
                      ))}
                      
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}
export default connect(null, { setBreadcrumbItems })(Operations)