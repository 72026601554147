import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import * as url from "../../helpers/url_helper"
import moment from 'moment'; 
import PropTypes from "prop-types";
import withRouter from 'components/Common/withRouter';

const animatedComponents = makeAnimated()
const Pays = (props) => {
  document.title = "Abidjan DAI APP | Utilisateurs";
  const navigate = useNavigate();

  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
  { title : "Paramètres", link : "#" }, { title : "Pays", link : "#" },]
  const [options, setOptions]= useState([]);
  const [region, setRegion]= useState(null);
  const [pays, setPays]= useState(null);
  const [codePays, setCodePays]= useState(null);
  const [paysList, setPaysList]= useState([]);
  const [errorMsg, setErrorMsg]= useState("");
  
  useEffect(() => { props.setBreadcrumbItems('Paramétrage Pays', breadcrumbItems); getPays(); getRegions() }, [])
  
  const savePays= ()=>{
    fetch(url.BACKEND_URL+"/country", {
      method: 'POST', headers: { 'Authorization': "Bearer "+localStorage.getItem("token"), Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({paysRegion: {rgId: region.value}, paysName: pays, paysCode: codePays})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { setPays(""); setCodePays(""); getPays(); } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else { setErrorMsg(responseJson.message);  }
    }) .catch((error) => {  });
    // const data= JSON.stringify({paysRegion: {rgId: region.value}, paysName: pays, paysCode: codePays});
    // console.log(data);
  }
  
  const getPays= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/country", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      //alert(responseJson.status)
      if (responseJson.status==200) { setPaysList(responseJson.data); } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }
  const getRegions= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/region", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      const regions= [];
      if (responseJson.status==200) { 
        responseJson.data.forEach(elmt => {
          regions.push({label: elmt.rgName, value: elmt.rgId})
        });
        setOptions(regions);
      } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }

  const testSup= ()=>{
    alert("Test Sup");
  }

  function handleRegion(region) { setRegion(region) }
  return (
    <React.Fragment>

      <Row>
        <Col xl={6}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                    <Col md={12}>
                      <div className="mb-3 templating-select select2-container">
                        <label className="control-label">Région</label>
                        <Select
                          value={region}
                          onChange={handleRegion}
                          options={options}
                          components={animatedComponents}
                        />
                      </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Nom du pays</label>
                          <input type="text" value={pays} onChange={(event)=>setPays(event.target.value)} className="form-control" placeholder="Nom du pays"/>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Code du pays</label>
                          <input type="text" value={codePays} onChange={(event)=>setCodePays(event.target.value)} className="form-control" placeholder="Code du pays"/>
                        </div>
                      </Col>
                      <Col md={12}><strong style={{ "color": "red"  }}>{errorMsg}</strong></Col>
                    </Row>
                    <div className="mt-4">
                      <button type="button" className="btn btn-primary w-md" onClick={()=>savePays()}>Enregistrer</button>
                    </div>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste des pays</CardTitle>
              
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Région</th>
                      <th>Nom du pays</th>
                      <th>Suprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                  {paysList.map((item, key) => (
                    <tr>
                    <th scope="row">{item.paysId}</th>
                    <td>{item.paysRegion.rgName}</td>
                    <td>{item.paysName}</td>
                    <td><a onClick={()=>testSup()} className="fas fa-window-close" style={{ fontSize: 20 }}></a></td>
                  </tr>
                  ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Pays);
