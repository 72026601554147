import React , {useEffect, useState} from "react"

import { Button, Col, FormGroup, InputGroup, Label, Row } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr"
import { connect } from "react-redux";
import Select from "react-select"
import makeAnimated from "react-select/animated"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import * as url from "./../../helpers/url_helper"
import moment from "moment";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { array } from "prop-types";

const Dashboard = (props) => {

  document.title = "Tableau de bord: UE DIGITAL";

  const breadcrumbItems = [
    { title: "UE DIGITAL", link: "#" },
    { title: "Tableau de bord", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Tableau de Bord Guichet', breadcrumbItems)
    loadGlobalStat()
    loadAgences()
    lastOperations()
    lastBordereau()
    loadBordByDelay()
    loadStatByDates()
  }, [])

  const loadGlobalStat=()=> {
    fetch(url.BACKEND_URL+"/operation/global-stats?start="+startDate+"&end="+endDate+"&user="+userJson.usId+"&agdest="+dest, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status==200) { 
        setTotalOp(responseJson.data.totalOp);
        setPendOp(responseJson.data.pendOp);
        setSendOp(responseJson.data.sentOp);
        setDoneOp(responseJson.data.doneOp);
        setArriveOp(responseJson.data.arrivedOp);
        setWithSms(responseJson.data.withSms);
        setCaGlobal(responseJson.data.caGlobal);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadBordByDelay=()=> {
    fetch(url.BACKEND_URL+"/bordereau/count-by-delay/"+userJson.usAgence.agId, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status==200) { 
        setSeries([{ name: 'Series A', data: [responseJson.data.pendSince2h, responseJson.data.pendSince4h, responseJson.data.pendSince6h, responseJson.data.pendSinceOther]}]);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const lastOperations=()=> {
    fetch(url.BACKEND_URL+"/operation?start=2024-08-01&end="+endDate+"&size=5&user="+userJson.usId+"&agdest="+dest, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        setLastOp(responseJson.data)
    } else {  }
    }) .catch((error) => {  });
  };
  
  const lastBordereau=()=> {
    fetch(url.BACKEND_URL+"/bordereau?start=2024-08-01&end="+endDate+"&size=5&user="+userJson.usId+"&agdest="+dest, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setLastBord(responseJson.data)
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadAgences= ()=> {
    let agencesArray= [{label: "Toutes les agences", value: ''}];
    fetch(url.BACKEND_URL+"/agence?etp="+userJson.usAgence.agEtp.etpId+"&size=100", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { console.log(userJson.usId);
        agencesArray=[
          ...agencesArray,
          ...responseJson.data.map(item=>({
          label: item.agNom,
          value: item.agId
        }))];
        setAgences(agencesArray);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const loadStatByDates= ()=> {
    let axeArray= []; let valArray= [];
    fetch(url.BACKEND_URL+"/operation/stat-by-dates?user="+userJson.usId, {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        axeArray=[
          ...axeArray,
          ...responseJson.data.map(item=>(item.date ))]; console.log(JSON.stringify(axeArray.reverse()));
        
        valArray=[
          ...valArray,
          ...responseJson.data.map(item=>(item.count))]; console.log(JSON.stringify(valArray.reverse()));
        setValDaylyStat(valArray);
        setAxeDaylyStat(axeArray);
    } else {  }
    }) .catch((error) => {  });
  };
  
  const userJson= JSON.parse(localStorage.getItem("user"));
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [totalOp, setTotalOp] = useState(0);
  const [pendOp, setPendOp] = useState(0);
  const [sendOp, setSendOp] = useState(0);
  const [arriveOp, setArriveOp] = useState(0);
  const [doneOp, setDoneOp] = useState(0);
  const [withSms, setWithSms] = useState(0);
  const [caGlobal, setCaGlobal] = useState(0);
  const [agences, setAgences] = useState([]);
  const [dest, setDest] = useState('');
  const [selectedDest, setSelectedDest] = useState(null);
  const [lastOp, setLastOp] = useState([]);
  const [lastBord, setLastBord] = useState([]);
  const [series, setSeries]= useState([{ name: 'Series A', data: [0, 0, 0, 0]}]);
  const optionGroup = [ { options: agences } ]
  const handleStartDate= (date)=>{ setStartDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleEndDate= (date)=>{ setEndDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleDestination= (event)=>{ setSelectedDest(event); setDest(event.value); }
  const [axeDaylyStat, setAxeDaylyStat]= useState([]);
  const [valDaylyStat, setValDaylyStat]= useState([]);
  

  const reports = [
    { title: "Créés", message: "Colis créés", iconClass: "cube-outline", total: totalOp, average: "+11%", badgecolor: "info" },
    { title: "En attente", message: "Total colis en attente", iconClass: "buffer", total: pendOp, average: "-29%", badgecolor: "danger" },
    { title: "En transit", message: "Total colis en transit", iconClass: "buffer", total: sendOp, average: "-29%", badgecolor: "danger" },
    { title: "Arrivés", message: "Total colis dépouillés", iconClass: "buffer", total: arriveOp, average: "-29%", badgecolor: "danger" },
    { title: "Livrés", message: "Total colis livrés", iconClass: "buffer", total: doneOp, average: "-29%", badgecolor: "danger" },
    { title: "Avec Sms", message: "Point colis avec SMS", iconClass: "message", total: withSms, average: "0%", badgecolor: "warning" },
    { title: "Revenu SMS", message: "Chiffre d'affaire SMS", iconClass: "briefcase-check", total: withSms*100, average: "+89%", badgecolor: "info" },
    { title: "Revenu Total", message: "Chiffre d'affaire réalisés", iconClass: "briefcase-check", total: caGlobal, average: "+89%", badgecolor: "info" },
  ]

  const columns = {
    columns: [
        ['En attente', pendOp],
        ['En transit', sendOp],
        ['Colis Arrivés', arriveOp],
        ['Colis Livrés', doneOp]
      ],
    type: 'donut',
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl="3">
          <FormGroup className="mb-4">
            <Label>Début</Label>
            <InputGroup>
              
              <Flatpickr
                className="form-control d-block"
                placeholder="Date début"
                value={startDate}
                onChange={handleStartDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xl="3">
          <FormGroup className="mb-4">
            <Label>Fin</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Fin"
                value={endDate}
                onChange={handleEndDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        
        <Col lg="3">
          <div className="mb-3">
            <Label>Destination</Label>
            <Select
              value={selectedDest}
              onChange={handleDestination}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col lg="3">
          <div className="mt-3">
            <Button type="button" style={{ width: "100%" }} onClick={loadGlobalStat} color="primary" data-toggle="Button" size="lg" className="mt-2 waves-effect waves-light">Rechargé</Button>
          </div>
        </Col>
      </Row>
      {/*mimi widgets */}
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="3">
          {/* Monthly Earnings */}
          <MonthlyEarnings 
            columns={columns}
           />
        </Col>

        <Col xl="5">
          {/* Email sent */}
          <EmailSent info={series} />
        </Col>

        <Col xl="4">
          <MonthlyEarnings2 infos={{ axe: axeDaylyStat, value:  valDaylyStat}} />
        </Col>

      </Row>

      <Row>
        <Col xl="6">
          {/* latest transactions */}
          <LatestTransactions latest={lastOp} />
        </Col>

        <Col xl="6">
          {/* latest orders */}
          <LatestOrders latest={lastBord} />
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);