import React,{useEffect, useState} from "react"

import { Row, Col, Card, CardBody, CardTitle, InputGroup, Label, FormGroup, Button, Modal, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { connect } from "react-redux";
import * as url from "../../helpers/url_helper"
import { setBreadcrumbItems } from "../../store/actions";
import moment from "moment"
import Switch from "react-switch"
import { Chrono } from "react-chrono";
import { BarLoader } from "react-spinners";
import { CircleLoader } from "react-spinners";

const Operations = (props) => {
  document.title = "Opérations | Interface des opérations";

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Tables", link: "#" },
    { title: "Responsive Table", link: "#" },
  ]
  const [loadingOps, setLoadingOps] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [agences, setAgences] = useState([]);
  const [dest, setDest] = useState('');
  const [numColis, setNumColis]= useState("")
  const [selectedColis, setSelectedColis] = useState(null);
  const [selectedDest, setSelectedDest] = useState(null);
  const [operations, setOperations] = useState([]);
  const [msgDeclare, setMsgDeclare] = useState("");
  const [errorDeclare, setErrorDeclare] = useState("");
  const [totalOps, setTotalOps] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const optionGroup = [ { options: agences } ]
  const [detail, setDetail] = useState(null);
  const handleStartDate= (date)=>{ setStartDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleEndDate= (date)=>{ setEndDate(moment(date[0]).format("YYYY-MM-DD")); }
  const handleDestination= (event)=>{ setSelectedDest(event); setDest(event.value); }
  const [modal_opera, setmodal_opera] = useState(false)
  const [modal_declare, setmodal_declare] = useState(false)
  const [modal_retrait, setmodal_retrait] = useState(false)
  const userJson= JSON.parse(localStorage.getItem("user"));

  const loadAgences= ()=> {
    let agencesArray= [{label: "Toutes les agences", value: ''}];
    fetch(url.BACKEND_URL+"/agence?etp="+userJson.usAgence.agEtp.etpId+"&size=100", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        agencesArray=[
          ...agencesArray,
          ...responseJson.data.map(item=>({
          label: item.agNom,
          value: item.agId
        }))];
        setAgences(agencesArray);
    } else {  }
    }) .catch((error) => {  });
  };

  function removeBodyCss() { document.body.classList.add("no_padding") }
  function tog_get_opera() { setmodal_opera(!modal_opera); removeBodyCss() }
  function tog_declare() { setmodal_declare(!modal_declare); removeBodyCss() }
  function tog_retrait() { setmodal_retrait(!modal_retrait); removeBodyCss() }

  const loadOperations=(page)=> { 
    setLoadingOps(true)
    fetch(url.BACKEND_URL+"/operation?start="+startDate+"&end="+endDate+"&page="+page+"&size=30&agdest="+userJson.usAgence.agId+"&agexp="+dest+"&code="+numColis+"&statNot=1", {method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(responseJson.data)
        setTotalOps(responseJson.totalElement)
        setTotalPage(responseJson.totalPage, 30)
        setCurrPage(responseJson.current_page, 30)
        setLoadingOps(false)
    } else { setLoadingOps(false) }
    }) .catch((error) => { setLoadingOps(false) });
  };
  const declareColis= ()=>{
    setLoadingAction(true)
    fetch(url.BACKEND_URL+"/operation/declare?opId="+selectedColis+"&usId="+userJson.usId, {method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(prevOperations => 
          prevOperations.map(op => 
            op.opId === selectedColis
              ? { ...op, opStat: { ...op.opStat, etatId: 3, etatLibelle: 'ARRIVE A DESTINATION' } }
              : op
          )
        );
        tog_declare()
        setLoadingAction(false)
    } else { setLoadingAction(false); setErrorDeclare(responseJson.message)}
    }) .catch((error) => { setLoadingAction(false); setErrorDeclare("Erreur innatendue")});
  }
  const retraitColis= ()=>{
    setLoadingAction(true)
    fetch(url.BACKEND_URL+"/operation/pickup?opId="+selectedColis+"&usId="+userJson.usId, {method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((responseJson) => { console.log(responseJson.data);
      if (responseJson.status==200) { 
        setOperations(prevOperations => 
          prevOperations.map(op => 
            op.opId === selectedColis
              ? { ...op, opStat: { ...op.opStat, etatId: 4, etatLibelle: 'COLIS LIVRE' } }
              : op
          )
        );
        tog_retrait()
        setLoadingAction(false)
    } else { setLoadingAction(false); setErrorDeclare(responseJson.message)}
    }) .catch((error) => { setLoadingAction(false); setErrorDeclare("Erreur innatendue")});
  }
  const getOperation=(item)=> {
    let timeLine=[];
    timeLine= [
      ...timeLine,
      ...item.actionColis.map(ite=>(
        {
          title: ite.actNom,
          cardTitle: ite.actNom,
          cardSubtitle: ite.actUser.usNom,
          cardDetailedText: moment(ite.actCreate).format("DD-MM-YYYY HH:mm:SS")
        }
      ))
    ]
    console.log(timeLine);
    return (
        <Row>
          <Col xl="7">
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0" data-pattern="priority-columns">
              <Table id="tech-companies-1" className="table table-striped table-bordered">
                <Tbody>
                  <Tr><Td>Agence départ</Td><Td><strong>{item.opAgexp.agNom}</strong></Td></Tr>
                  <Tr><Td>Agence Bénéficiaire</Td><Td><strong>{item.opAgdest.agNom}</strong></Td></Tr>
                  <Tr><Td>Expéditeur</Td><Td><strong>{item.opExp} ({item.opPhonexp})</strong></Td></Tr>
                  <Tr><Td>Bénéficiaire</Td><Td><strong>{item.opDest} ({item.opDestphone})</strong></Td></Tr>
                  <Tr><Td>Code du colis</Td><Td><strong>{item.opCode}</strong></Td></Tr>
                  <Tr><Td>Emballage</Td><Td><strong>{item.detail[0].opdEmbal.embalLibelle}</strong></Td></Tr>
                  <Tr><Td>Nature</Td><Td><strong>{item.detail[0].opdNature.natLibelle}</strong></Td></Tr>
                  <Tr><Td>Valeur</Td><Td><strong>{item.opValeur} FCFA</strong></Td></Tr>
                  <Tr><Td>Frais</Td><Td><strong>{item.opFrais} FCFA</strong></Td></Tr>
                </Tbody>
              </Table>
              </div>
            </div>
          </Col>
          <Col xl="5" style={{ height: "500px", overflow: "auto" }}>
            <Chrono disableToolbar={true} mode="VERTICAL_ALTERNATING" cardHeight={80} fontSizes={{ title: "9pt", cardTitle: "9pt", cardSubtitle: "7pt", cardDetailedText: "5pt"}} cardWidth={180} items={timeLine} />
          </Col>
        </Row>);};

  useEffect(() => {
    props.setBreadcrumbItems('Vos envois enregistrés')
    loadAgences()
    loadOperations(0)
  }, [])


  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal_opera} toggle={() => { tog_get_opera() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Consultation détails colis</h5>
        </div>
          {detail && getOperation(detail)}
      </Modal>
      <Modal size="sm" isOpen={modal_declare} toggle={() => { tog_declare() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Déclaration colis</h5>
        </div>
        <div className="modal-body">
          <h5 className="txt-primary">Confirmez-vous le réception de ce colis à votre agence?</h5>
          </div>
        <div className="modal-footer">
          <span>
            <strong className="txt-success">{msgDeclare}</strong>
            <strong className="txt-danger">{errorDeclare}</strong>
            {loadingAction ? (<CircleLoader/>): null}
          </span>
          <button type="button" onClick={() => {tog_declare() }} className="btn btn-danger waves-effect" data-dismiss="modal">Fermer </button>
          <button type="button" onClick={()=>declareColis()} className="btn btn-primary waves-effect waves-light">Confirmez</button>
        </div>
      </Modal>
      <Modal size="sm" isOpen={modal_retrait} toggle={() => { tog_retrait() }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">Confirmation de retrait</h5>
        </div>
        <div className="modal-body">
          <h5 className="txt-primary">Confirmez-vous le retrait de ce colis à votre agence?</h5>
          </div>
        <div className="modal-footer">
          <span>
            <strong className="txt-success">{msgDeclare}</strong>
            <strong className="txt-danger">{errorDeclare}</strong>
            {loadingAction ? (<CircleLoader/>): null}
          </span>
          <button type="button" onClick={() => {tog_retrait() }} className="btn btn-danger waves-effect" data-dismiss="modal">Fermer </button>
          <button type="button" onClick={()=>retraitColis()} className="btn btn-primary waves-effect waves-light">Confirmez</button>
        </div>
      </Modal>
      <Row>
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Début</Label>
            <InputGroup>
              
              <Flatpickr
                className="form-control d-block"
                placeholder="Date début"
                value={startDate}
                onChange={handleStartDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xl="2">
          <FormGroup className="mb-4">
            <Label>Fin</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block" 
                placeholder="Date Fin"
                value={endDate}
                onChange={handleEndDate}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d"
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        
        <Col lg="2">
          <div className="mb-3">
            <Label>Provenance</Label>
            <Select
              value={selectedDest}
              onChange={handleDestination}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col xl="2">
          <FormGroup className="mb-3">
            <div className="mb-0">
              <label className="form-label" htmlFor="formrow-firstname-input">Numéro colis</label>
              <input type="text" className="form-control" value={numColis} onChange={(event)=>setNumColis(event.target.value)} id="exp" placeholder="Numéro du colis"/>
            </div>
          </FormGroup>
        </Col>
        <Col lg="2">
          <div className="mt-3">
            <Button type="button" style={{ width: "100%" }} onClick={()=>{loadOperations(0)}} color="primary" data-toggle="Button" size="lg" className="mt-2 waves-effect waves-light">Recharger</Button>
          </div>
        </Col>
      </Row>
     <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">{totalOps} élément(s) </CardTitle>
              <Card>
                {totalPage ==0 ? ( null): totalPage>=25 ? (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: 25}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadOperations(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(totalPage-1)}>......{totalPage-1}</PaginationLink></PaginationItem>
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>): (<nav aria-label="...">
                  <Pagination>
                      {Array.from({ length: totalPage}, (_, index) => (
                      <PaginationItem active={index==currPage} ><PaginationLink  onClick={()=>loadOperations(index)}>{index+1}</PaginationLink></PaginationItem>
                      ))}
                      <PaginationItem><PaginationLink onClick={()=>loadOperations(currPage+1)}>Suivant</PaginationLink></PaginationItem>
                  </Pagination>
                </nav>)}
              </Card>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <div>
                    {loadingOps ? (<BarLoader width={"100%"} height={6}/>): null}
                  </div>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Provenance</Th>
                        <Th data-priority="1">Code</Th>
                        <Th data-priority="3">Date heure</Th>
                        <Th data-priority="3">Expéditeur</Th>
                        <Th data-priority="1">Bénéficiaire</Th>
                        <Th data-priority="3">Frais</Th>
                        <Th data-priority="3">Etat</Th>
                        <Th data-priority="6">Détail</Th>
                        <Th data-priority="6">Suivi</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {operations.map((item, key)=>(
                      <Tr>
                        <Th>
                          <strong>{item.opAgexp.agNom}</strong>
                        </Th>
                        <Td>{item.opCode}</Td>
                        <Td>{moment(item.opCreate).format("DD-MM-YY HH:mm:ss")}</Td>
                        <Td>{item.opExp} ({item.opPhonexp})</Td>
                        <Td>{item.opDest} ({item.opDestphone})</Td>
                        <Td>{item.opFrais} FCFA</Td>
                        <Td style={{ fontSize: 9 }}><strong>{item.opStat.etatLibelle}</strong></Td>
                        <Td><Button onClick={()=>{setDetail(item); setTimeout(() => { tog_get_opera() }, 100);}}><i className="fa fa-eye"></i></Button></Td>
                        <Td>{item.opStat.etatId==2 ? (<Button onClick={()=>{setSelectedColis(item.opId); tog_declare()}} className="btn-primary">Déclarer</Button>): item.opStat.etatId==3 ? (<Button onClick={()=>{setSelectedColis(item.opId); tog_retrait()}} className="btn-success">Retirer</Button>):(null)}</Td>
                      </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}
export default connect(null, { setBreadcrumbItems })(Operations);