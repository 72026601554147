import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import * as url from "./../../helpers/url_helper"
import moment from 'moment'; 

const animatedComponents = makeAnimated()
const Role = (props) => {
  document.title = "Abidjan DAI APP | Utilisateurs";

  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
    { title : "Paramètres", link : "#" }, { title : "Utilisateurs", link : "#" },]
  const [role, setRole]= useState(null);
  const [roles, setRoles]= useState([]);
  
  useEffect(() => { props.setBreadcrumbItems('Basic Tables', breadcrumbItems); getRoles() }, [])
  const saveRole= ()=>{
    fetch(url.BACKEND_URL+"/role", {
      method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({roleLibelle: role})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { setRole(""); getRoles(); } else {  }
    }) .catch((error) => {  });
  }
  
  const getRoles= ()=>{
    fetch(url.BACKEND_URL+"/role", {
      method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { setRoles(responseJson.data); console.log(responseJson.data) } else {  }
    }) .catch((error) => {  });
  }
  return (
    <React.Fragment>

      <Row>
        <Col xl={6}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Role</label>
                          <input type="text" value={role} onChange={(event)=>setRole(event.target.value)} className="form-control" id="role" placeholder="Nom du role"/>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4">
                      <button type="button" className="btn btn-primary w-md" onClick={()=>saveRole()}>Enregistrer</button>
                    </div>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste des rôles</CardTitle>
              
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du role</th>
                      <th>Suprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                  {roles.map((item, key) => (
                    <tr>
                    <th scope="row">{item.roleId}</th>
                    <td>{item.roleLibelle}</td>
                    <td>Del</td>
                  </tr>
                  ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Role);
