import React, { useEffect, useState } from "react"


import { Table, Row, Col, Card, CardBody, CardTitle, FormGroup, Form } from "reactstrap"
import { connect } from "react-redux";
import Select from "react-select"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import Switch from "react-switch"
import * as url from "../../helpers/url_helper"
import { array } from "prop-types";
import { useNavigate } from "react-router-dom";

const Offsymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        fontWeight: 'bold',
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      OFF
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        fontWeight: 'bold',
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      ON
    </div>
  )
}
const animatedComponents = makeAnimated()
const Dojo = (props) => {
  const navigate = useNavigate();
  document.title = "Abidjan DAI APP | Utilisateurs";
  
  const [options, setOptions]= useState([])
  const [paysOptions, setPaysOptions]= useState([])
  const [rangOptions, setRangOptions]= useState([])
  const [dojos, setDojos]= useState([])
  const [pays, setPays]= useState(null)
  const [rang, setRang]= useState(null)
  const [nom, setNom]= useState("")
  const [location, setLocation]= useState("")
  const [email, setEmail]= useState("")
  const [phone, setPhone]= useState("")
  const [respo, setRespo]= useState("")
  const [errorMsg, setErrorMsg]= useState("")
  const breadcrumbItems = [
    { title : "Abidjan Dai App", link : "#" },
    { title : "Paramètres", link : "#" },
    { title : "Dojos", link : "#" },
  ]
  const [selectedMulti3, setselectedMulti3] = useState([])
  const [switch1, setswitch1] = useState(true)

  useEffect(() => { props.setBreadcrumbItems('Dojos', breadcrumbItems); getRang(); getPays(); getDojos() }, [])
  
  const changeUserStatus= (usId)=>{
    //setswitch1(!switch1);
    setswitch1(false);
  }
  const getRang= ()=>{
    const ranks= [];
    fetch(url.BACKEND_URL+"/rang", {
      method: 'GET', headers: { 'Authorization': "Bearer "+localStorage.getItem("token"), Accept: 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        responseJson.data.forEach(elmt => {
        ranks.push({label: elmt.rdjName, value: elmt.rdjId})
      });
      setRangOptions(ranks);
       } else {  }
    }) .catch((error) => {  });
  }

  const getPays= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/country", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      const countries=[];
      //alert(responseJson.status)
      if (responseJson.status==200) { 
        responseJson.data.forEach(elmt => {
        countries.push({label: elmt.paysName, value: elmt.paysId})
      });
      setPaysOptions(countries);
      } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }
  
  const getDojos= ()=>{
    //alert(localStorage.getItem("token"));
    fetch(url.BACKEND_URL+"/dojo", {
      method: 'GET', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), 'Accept': 'application/json', 'Content-Type': 'application/json' }})
    .then((response) => response.json())
    .then((responseJson) => { 
      //alert(responseJson.status)
      if (responseJson.status==200) { 
        setDojos(responseJson.data);
      } 
      else if(responseJson.status==401 || responseJson.status==403) { alert("Vous n'êtes plus connecté"); navigate('/logout');}
      else {}
    }) .catch((error) => {  });
  }
  function handlePays(pays) { setPays(pays) }
  function handleRang(rang) { setRang(rang) }
  
  const saveDojo= ()=>{
    fetch(url.BACKEND_URL+"/dojo", {
      method: 'POST', headers: {'Authorization': "Bearer "+localStorage.getItem("token"), Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({djPays: {paysId: pays.value}, djRang: {rdjId: rang.value}, djName: nom, djLocation: location, djPhone: phone, djEmail: email, djRespo: respo})})
    .then((response) => response.json())
    .then((responseJson) => { 
      if (responseJson.status==200) { 
        setNom(""); setLocation(""); setEmail(""); setPhone(""); setRespo(""); getDojos();
       } else { setErrorMsg(responseJson.message); }
    }) .catch((error) => {  });
    
  }
  return (
    <React.Fragment>

      <Row>
        <Col xl={5}>
          <Card>
            <CardBody>
              <Col lg={12}>
                <div className="mt-4">
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 templating-select select-container">
                          <label className="control-label">Pays</label>
                          <Select
                            value={pays}
                            onChange={handlePays}
                            options={paysOptions}
                            components={animatedComponents}
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                        </div>
                        </Col>
                        <Col md={6}>
                        <div className="mb-3 templating-select select-container">
                          <label className="control-label">Rang</label>
                          <Select
                            value={rang}
                            onChange={handleRang}
                            options={rangOptions}
                            components={animatedComponents}
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                        </div>
                        </Col>
                        
                      </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Nom du Centre</label>
                          <input type="text" className="form-control" id="nom" value={nom} onChange={(event)=>setNom(event.target.value)} placeholder="Nom du centre"/>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-password-input">Localisation</label>
                          <input type="text" className="form-control" id="formrow-password-input" value={location} onChange={(event)=>setLocation(event.target.value)} placeholder="Localisation du dojo"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Email</label>
                          <input type="email" className="form-control" id="formrow-email-input" value={email} onChange={(event)=>setEmail(event.target.value)} placeholder="Email du dojo"/>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-password-input">Téléphone</label>
                          <input type="text" className="form-control" id="formrow-password-input" value={phone} onChange={(event)=>setPhone(event.target.value)} placeholder="Téléphone"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="formrow-email-input">Responsable du Dojo</label>
                          <input type="text" className="form-control" id="formrow-email-input" value={respo} onChange={(event)=>setRespo(event.target.value)} placeholder="Nom du responsable"/>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4">
                      <button type="button" onClick={()=>saveDojo()} className="btn btn-primary w-md">Enregistrer</button>
                    </div>
                    <Row>
                      <Col md={12}>
                        <span>{errorMsg}</span>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col xl={7}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste Dojos</CardTitle>
              

              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      {/* <th>Région</th>
                      <th>Pays</th> */}
                      <th>Nom</th>
                      <th>Téléphone</th>
                      <th>Email</th>
                      <th>Responsable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dojos.map((item, key)=>(
                      <tr>
                      {/* <th scope="row">{item.djId}</th> */}
                      {/* <td>{item.djPays.paysRegion.rgName}</td>
                      <td>{item.djPays.paysName}</td> */}
                      <td>{item.djName}</td>
                      <td>{item.djPhone}</td>
                      <td>{item.djEmail}</td>
                      <td>{item.djRespo}</td>
                    </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dojo);
