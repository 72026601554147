import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: ['#ff8C5C'],
                chart: {
                    toolbar: {
                        show: !1,
                    },
                },
                dataLabels: {
                    enabled: !1
                },
                stroke: {
                    curve: 'smooth',
                    width: 0.8,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.8
                    },
                },
                xaxis: {
                    categories: ['2H-04h', '04h-06h', '06h-08h' , '08h et plus'],
                    axisBorder: {
                        show: !1
                    },
                    axisTicks: {
                        show: !1
                    }
                },
                legend: {
                    show: !1
                },
            },
            series: [
                {
                    name: 'Series A',
                    data: [113, 150, 89, 180]
                }
            ],
        }
    }
    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Bordereau par délai d'expédition</h4>

                        <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.props.info} type="area" height="300" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MonthlyEarnings;